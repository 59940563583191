<template>
  <div class="main">
    <div class="wxapp">
      <span class="tip">小程序/渠道:</span>
      <div class="content">
        <a-checkable-tag :checked="typeId == 0" @change="changeType(0)">全部</a-checkable-tag>
        <a-checkable-tag v-for="item in wxMiniData" :key="item.appId" :checked="item.appId == typeId" @change="changeType(item.appId)">{{item.back}}</a-checkable-tag>
      </div>
    </div>
    <a-divider dashed/>
    <a-row>
      <a-col :span="24">
        <a-space>
          <span>查询:</span>
          <a-input placeholder="按IP地址搜索" style="width: 160px;" allowClear v-model="ipOwner" />
          <a-input placeholder="按IP搜索" style="width: 160px;" allowClear v-model="ip" />
          <a-button type="primary" @click="getListData(1)" :loading="listLoading">搜索</a-button>
        </a-space>
      </a-col>
    </a-row>
    <!-- 菜单列表 -->
    <a-spin tip="Loading..." :spinning="listLoading">
      <a-table :columns="tableTitle" :data-source="data" :pagination="listPagination" size="middle" :rowKey="record=>record.id" style="margin-top:16px">
        <span slot="action" slot-scope="text, record">
          <a-popconfirm v-if="record.black_id == false" title="被拉黑用户只能访问读书页面,确定?" @confirm="black(record.id)" okText="Yes" cancelText="No" placement="left"><a-button size="small" icon="alert">拉黑</a-button></a-popconfirm>
          <a-badge v-else status="error" text="已被拉黑" />
        </span>
      </a-table>
    </a-spin>
    <!-- 菜单列表 End -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableTitle: [
        { title: "小程序ID", dataIndex: "app_id", key: "app_id" },
        { title: "最后访问IP", dataIndex: "last_login_ip", key: "last_login_ip"},
        { title: "最后访问IP地址", dataIndex: "last_ip_owner", key: "last_ip_owner"},
        { title: "注册时间", dataIndex: "create_time", key: "create_time"},
        { title: "最后访问时间", dataIndex: "last_login_time", key: "last_login_time"},
        { title: "unionID", dataIndex: "unionid", key: "unionid"},
        { title: "操作", key: "action", scopedSlots: { customRender: "action" },width:"100px"}
      ],
      typeId: 0,
      wxMiniData: [],
      data: [],
      listLoading: false, //列表loadding
      ipOwner: '',
      ip:'',
      listPagination: {
        onChange: page => {
          this.getListData(page);
        },
        showQuickJumper: true,
        current: 1,
        total: 0,
        pageSize: 15
      }
    };
  },
  mounted() {
    this.getMiniList()
    this.getListData(1);
  },
  methods: {
    getMiniList(){
      this.$http
        .get("/admin/admin/getMiniList")
        .then(res => {
          //请求成功后的处理函数
          this.wxMiniData = res.data.result
        })
        .catch(() => {
          //请求失败后的处理函数
        });
    },
    getListData(page) {
      this.listLoading = true;
      this.$http
        .get("/admin/user/list", {params: {
          page: page, 
          ip_owner: this.ipOwner, 
          ip: this.ip,
          app_id: this.typeId
        }})
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.data = res.data.result.data;
          this.listPagination.total = res.data.result.count;
          this.listPagination.current = page;
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    //切换版块
    changeType(val){
      if(this.typeId === val)return false
      this.typeId = val
      this.listPage = 1
      this.getListData(1)
    },
    black(id){
      this.$http
        .get("/admin/user/black", { params: { id: id }})
        .then(() => {
          this.getListData(this.listPagination.current);
        })
    }
  }
};
</script>

<style lang="scss" scope>
  .wxapp{
    display: flex;
    flex-direction: row;
    > .tip{
      margin-right: 8px;
    }
    > .content{
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > .ant-tag{
        margin-bottom: 4px;
      }
    }
  }
  .footer {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    text-align: right;
  }
  .ant-tree-child-tree-open li {
    display: inline-table;
  }
</style>
